import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

class Footer extends Component{
    render(){
        // let brianData = this.props.brianData;
        return(
            <section className="footer-area">
                <div className="container">
                    <div className="footer-content">
                        <Fade top cascade>
                            <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
                                <img src={require('../image/new-images/bee-squared-logo-sm-blue.png')} alt=""/>
                            </Link>
                        </Fade>
                        {/* <Fade top cascade>
                            <ul className="list_style">
                                {
                                    brianData.socialLinks.map(item =>{
                                        return(
                                            <li key={item.name}>
                                                <a  href={item.url}><i className={item.className}></i></a>
                                            </li>
                                        )
                                    })
                                }
                            </ul> */}
                        <h6>Craftfully built with love by <a href="/">Brian Bouchard</a></h6>
                    </div>
                </div>
            </section>
        )
    }
}
export default Footer;