import React from 'react';
import Navbar from './component/Navbar/Navbar';
import TypeBanner from './component/Banner/TypeBanner';
import About from './component/About';
import Portfolio from './component/Portfolio/Portfolio';
import TechSkills from './component/TechSkills';
import MapContainer from './component/Map';
import Contact from './component/Contact';
import Footer from './component/Footer';
import brianData from './component/brianData';

export const Home2 = () => (
  <div className="body_wrapper">
      <Navbar mContainer="custome_container" mainlogo="bee-squared-logo-sm-white.png" stickylogo="bee-squared-logo-sm-black.png" />
      <TypeBanner brianData={brianData} />
      <About aClass="about_area" brianData={brianData} />
      <Portfolio projects={brianData.projects} />
      <TechSkills />
      <MapContainer />
      <Contact brianData={brianData} />
      <Footer brianData={brianData} />
  </div>
)


