import React, {Component} from 'react';
import { Parallax} from 'react-parallax';
import { Reveal, Fade } from 'react-reveal';
import Typer from './Typer';

class TypeBanner extends Component {
    render(){
        let brianData = this.props.brianData;
        return(
            <section id="home">
                <Fade duration={3000}>
                    <Parallax bgImage={require('../../image/new-images/profile.jpeg')} strength={0} className="banner_area">
                        <div className="container">
                            <div id="banner_img" className="banner_content">
                                <Reveal effect="fadeInUp"><h5 className="wow fadeInUp animated">Hello, I'm</h5></Reveal>
                                <Reveal effect="fadeInUp" duration={2000}><h2 id="person-name" className="wow fadeInLeft animated">{brianData.name2}</h2></Reveal>
                                <Reveal effect="fadeInUp" duration={2000}> <Typer heading={'I am'} dataText={["a Full Stack Software Engineer","a Web Developer","an AWS Certified Cloud Practitioner", "an Athlete", "a Cycling Enthusiast"]} /></Reveal>
                            </div>
                        </div>
                    </Parallax>
                </Fade>
            </section>
        );
    }
}

export default TypeBanner;