import React, {Component} from 'react';
import Sectiontitle from './Banner/Sectiontitle';
import Fade from 'react-reveal/Fade';

class TechSkills extends Component {
    render() {

      return(
        <section className="techSkills">
          <Sectiontitle Title="Tech Skills" TitleP="I'm an education junkie at heart. I push myself to learn new technologies & explore modern cutting-edge approaches to solve complex problems."/>
          <div className="techSkillsContainer">
            <Fade bottom duration={2000}>
              <div className="skillSection">
                <div className="header">Front-End</div>
                <div className="skillItem">JavaScript (ES5/ES6+)</div>
                <div className="skillItem">React / React Native</div>
                <div className="skillItem">TypeScript</div>
                <div className="skillItem">NextJS</div>
                <div className="skillItem">Angular</div>
                <div className="skillItem">Redux</div>
                <div className="skillItem">jQuery</div>
                <div className="skillItem">HTML</div>
                <div className="skillItem">CSS / Flexbox / Grid</div>
                <div className="skillItem">Responsive Design</div>
                <div className="skillItem">Accessibility</div>
                <div className="skillItem">Mapbox / Leaflet / Google Maps</div>
              </div>
            </Fade>
            <Fade bottom duration={1600}>
              <div className="skillSection">
                <div className="header">Back-End</div>
                <div className="skillItem">AWS</div>
                <div className="skillItem">Node.js</div>
                <div className="skillItem">Python</div>
                <div className="skillItem">Express.js</div>
                <div className="skillItem">SQL</div>
                <div className="skillItem">MySQL</div>
                <div className="skillItem">MongoDB / Mongoose</div>
                <div className="skillItem">PostgreSQL</div>
                <div className="skillItem">OAuth</div>
                <div className="skillItem">APIs / Microservices</div>
              </div>
            </Fade>
            <Fade bottom duration={1200}>
              <div className="skillSection">
                <div className="header">Tools</div>
                <div className="skillItem">AWS</div>
                <div className="skillItem">Docker</div>
                <div className="skillItem">Jenkins</div>
                <div className="skillItem">Git / NPM</div>
                <div className="skillItem">Sumologic</div>
                <div className="skillItem">NetSuite</div>
                <div className="skillItem">Snapfulfil</div>
                <div className="skillItem">Atlassian</div>
                <div className="skillItem">Firebase</div>
                <div className="skillItem">New Relic</div>
                <div className="skillItem">Pendo</div>
                <div className="skillItem">Sketch / Invision / Figma</div>
              </div>
            </Fade>
            <Fade bottom duration={800}>
              <div className="skillSection">
                <div className="header">Testing and Debugging</div>
                <div className="skillItem">Jest</div>
                <div className="skillItem">Enzyme</div>
                <div className="skillItem">Insomnia</div>
                <div className="skillItem">Postman</div>
              </div>
            </Fade>
            <Fade bottom duration={400}>
              <div className="skillSection">
                <div className="header">Methodologies</div>
                <div className="skillItem">OOP</div>
                <div className="skillItem">Agile / Scrum</div>
                <div className="skillItem">Pair Programming</div>
                <div className="skillItem">Test-Driven Development</div>
                <div className="skillItem">Continuous Integration</div>
              </div>
            </Fade>
          </div>
        </section>
      )
    }
  }

  export default TechSkills;
