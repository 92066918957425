const brianData = {
    "name": "Brian Bouchard",
    "name2": "Brian Bouchard",
    "name3": "Brian Bouchard",
    "role1": "Full Stack Software Engineer",
    "role2": "AWS Certified Cloud Practitioner",
    "ptext": "Brian Bouchard in Denver, Colorado",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "http://www.linkedin.com/brian-bouchard",
          "className": "social_linkedin"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/",
          "className":"social_twitter"
        },
        {
          "name":"googleplus",
          "url":"http://www.googleplus.com",
          "className":"social_googleplus"
        },
        {
            "name":"pinterest",
            "url":"http://www.pinterest.com",
            "className":"social_pinterest"
          }
      ],
      "aboutme": "About Me",
      "aboutdetails": "I am a value-driven software engineer with a passion for building engaging products, user interfaces, and user experiences that delight and enable meaningful interactions. I also bring a wealth of business experience gained from 10+ years of software product, program, and project management while working for Fortune 500 companies as well as fast-paced startups. In my free time, I race bicycles across several disciplines. I am enlivened about learning how to work for, and be a part of a cohesive and dynamic team, along with creating representation in cycling that will encourage and inspire other young athletes to pursue their dreams, whatever they may be.",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Web Design",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Development",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 3,
            "iconName": "icon-presentation",
            "serviceTitle": "Marketing",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 4,
            "iconName": "icon-linegraph",
            "serviceTitle": "Strategy",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 5,
            "iconName": "icon-gears",
            "serviceTitle": "Support",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          },
          {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "We’re experienced",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: Denver, Colorado',
            },
            // {
            //   id: 2,
            //   icon: 'icon_phone',
            //   text: 'Phone: +1.763.257.2292',
            // },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: b.bouch@gmail.com',
            },
            // {
            //   id: 4,
            //   icon: 'icon_globe',
            //   text: 'github: https://github.com/bee-squared',
            // }
          ]
        },
      ],
    contacttitle: 'Contact Form',
    projects: [
      {
        id: 1,
        projectName: 'Portfolio',
        img: 'portfolio.png',
        hoverImg: 'portfolio.png',
        description: 'This responsive web application is my personal project portfolio web site. It aims to give recruiters, potential employers, and colleagues a high-level view of my skill set, qualifications, and a hint of my personality.',
        githubUrl: 'https://www.linkedin.com/in/brian-bouchard/',
        type: 'web'
      },
      {
        id: 2,
        projectName: 'Navigator',
        img: 'navigator.png',
        hoverImg: 'navigator.png',
        description: 'Navigator is a responsive full stack web application that allows athletes to track their activities and get recommendations based on user goals.',
        githubUrl: 'https://github.com/bee-squared/navigator',
        type: 'web'
      },
      {
        id: 3,
        projectName: 'BelezaPura',
        img: 'BelezaPura-Portfolio.png',
        hoverImg: 'BelezaPura-Portfolio.png',
        description: 'BelezaPura is a React Native mobile application that allows users to shop for and sell surfing products.',
        githubUrl: 'https://github.com/bee-squared/BelezaPura',
        type: 'mobile'
      },
      {
        id: 4,
        projectName: 'Guess A Number',
        img: 'guess-a-number.png',
        hoverImg: 'guess-a-number.png',
        description: 'Guess-A-Number is a react native game that allows users to interact with their device to guess a defined number based on the user\'s input. Available for both iOS and Android platforms.',
        githubUrl: 'https://github.com/bee-squared/guess-a-number',
        type: 'mobile'
      },
      {
        id: 5,
        projectName: 'Exact Science Apparel',
        img: 'exact-science-apparel.png',
        hoverImg: 'exact-science-apparel.png',
        description: 'Exact Science Apparel is the complete redesign and reimplementation of an outdated E-commerce application.',
        githubUrl: 'https://github.com/Exact-Science/es_apparel_fe',
        type: 'web'
      },
      {
        id: 6,
        projectName: 'Cryptocurrency',
        img: 'cryptocurrency.png',
        hoverImg: 'cryptocurrency.png',
        description: 'Cryptocurrency is a single-page app for viewing historical price data for the Bitcoin Index (BPI).',
        githubUrl: 'https://github.com/bee-squared/cryptocurrency',
        type: 'web'
      },
      {
        id: 7,
        projectName: 'MyMeals',
        img: 'meals.png',
        hoverImg: 'meals.png',
        description: 'React Native App that allows users to find meals and favoritize them in the effort to efficiently find meals that are most important.',
        githubUrl: 'https://github.com/bee-squared/MyMeals',
        type: 'mobile'
      },
      {
        id: 8,
        projectName: 'Restaurants',
        img: 'restaurants-hover.png',
        hoverImg: 'restaurants-hover.png',
        description: 'Restaurant List is a responsive web application that pulls restaurant data from an API, displays that data in a table, and allows users to search and filter that data.',
        githubUrl: 'https://restaurant-list-2cb09.web.app/',
        type: 'web'
      },
    ],
}

export default brianData;

