import Typography from '@material-ui/core/Typography';
import React, {Component} from 'react';

export default class FeaturedContentCard extends Component{
  render(){
    let {header, title, image, alt} = this.props;
    return(
      <div className='featured_content_card'>
        <Typography style={{ color: '#404040', fontWeight: 'bold'}} gutterBottom variant="h6" component="h6">
          {header}
        </Typography>
        <Typography style={{ marginBottom: '18px'}} className="projectDescription" variant="body2" color="textSecondary" component="p">
          {title}
        </Typography>
        <img style={{ width: '100%', borderRadius: 5 }} src={image} alt={alt} />
      </div>
    )
  }
}