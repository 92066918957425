import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Fade, Reveal } from 'react-reveal';

class About extends Component {
  render() {
    let brianData = this.props.brianData;
    var { aClass } = this.props;
    return (
      <section className={`${aClass}`} id='about'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 d-flex align-items-center'>
              <Fade left cascade duration={1000}>
                <div className='about_content'>
                  <h2 className='t_color'>{brianData.aboutme}</h2>
                  <h6>
                    {brianData.role1}
                    <br />
                    {brianData.role2}
                  </h6>
                  <p>{brianData.aboutdetails}</p>
                  <HashLink className='theme_btn active' to='#contact'>
                    Hire Me
                  </HashLink>
                  <a
                    href={require('../document/BrianBouchard-Resume.pdf')}
                    download='BrianBouchard-Resume.pdf'
                    className='theme_btn'
                  >
                    Download CV
                  </a>
                </div>
              </Fade>
            </div>
            <div className='col-lg-5'>
              <Reveal effect='fadeInRight' duration={1500}>
                <div className='about_img'>
                  <img
                    id='headshot'
                    src={require('../image/new-images/headshot.jpg')}
                    alt='About Profile'
                  />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
