import React, {Component} from 'react';

class SectionTitleTwo extends Component {
    render(){
        let {tCenter, stitle, btitle, mtitle} = this.props;
        return(
            <div style={{ marginBottom: '0px' }} className={`section_title_two ${tCenter}`}>
                <h2>{btitle}</h2>
                <h4>{mtitle}</h4>
                <h6>{stitle}</h6>
            </div>
        )
    }
}

export default SectionTitleTwo;