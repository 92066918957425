import React, { Component } from 'react';
import ImgMediaCard from './ImgMediaCard';
import Sectiontitle from '../../component/Banner/Sectiontitle';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import { Fade, Reveal } from 'react-reveal';
import FeaturedContentCard from './FeaturedContentCard';
import tpcSavedSearches from '../../image/portfolio/tpc-saved-searches.gif';
import tpcCpo from '../../image/portfolio/tpc-cpo.gif';
import tpcSubscriptionGroupNotifications from '../../image/portfolio/tpc-subscription-group-notifications.gif';
import tpcSellTrade from '../../image/portfolio/tpc-sell-trade-1024.gif';
class portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'all',
    };
  }
  render() {
    const { type } = this.state;
    let { pClass, projects } = this.props;
    let filteredProjects;
    switch (type) {
      case 'web':
        filteredProjects = projects.filter((project) => {
          return project.type === 'web' ? true : false;
        });
        break;
      case 'mobile':
        filteredProjects = projects.filter((project) => {
          return project.type === 'mobile' ? true : false;
        });
        break;
      default:
        filteredProjects = projects;
    }

    const handleTypeChange = (e) => {
      let typeSelection = e.target.name;
      this.setState({ type: typeSelection });
    };

    const showFeaturedProjects = Boolean(this.state.type === 'all' || this.state.type === 'web');

    return (
      <section className={`portfolio_area ${pClass}`} id='portfolio'>
        <Sectiontitle
          Title='My Portfolio'
          TitleP='I love what I do. I take great pride in what I do.'
        />
        <Fade duration={2000}>
          <Reveal effect='fadeInDown' duration={1500}>

            <div className='portfolio_button_container'>
              <button
                name='all'
                className={
                  type === 'all'
                    ? 'theme_btn_small_active'
                    : 'theme_btn_small'
                }
                onClick={handleTypeChange}
              >
                All
              </button>
              <button
                name='web'
                className={
                  type === 'web'
                    ? 'theme_btn_small_active'
                    : 'theme_btn_small'
                }
                onClick={handleTypeChange}
              >
                Web
              </button>
              <button
                name='mobile'
                className={
                  type === 'mobile'
                    ? 'theme_btn_small_active'
                    : 'theme_btn_small'
                }
                onClick={handleTypeChange}
              >
                Mobile
              </button>
            </div>
          </Reveal>
        </Fade>
        {showFeaturedProjects && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '25px',
              paddingTop: '25px',
              paddingBottom: '25px',
            }}
            className='portfolio_content'
          >
            <SectionTitleTwo tCenter='h6' mtitle="Featured: The Pro's Closet (TPC)" />
            <FeaturedContentCard
              header='Saved Searches'
              title='Saved Searches allows users to sign up for, and manage, notifications when out of stock products &#40;such as bikes, wheels, and frames&#41; become available&#41;. This is super helpful for customers because it allows them to be notified as soon as their criteria are met, whether those are granularly or broadly defined, so they can purchase those product&#40;s&#41;.'
              image={tpcSavedSearches}
              alt='Saved Searches'
            />
            <FeaturedContentCard
              header='Subscription Group Notifications'
              title='An email and SMS subscription group notification preference center gives power to the customers, so they can decide which communications are best for them, or opt out from all future notifications. This full stack feature leverages an AWS backend (e.g. API Gateway, Lambdas, etc.) to communicate with Braze (a customer engagement platform) and send notifications to configured audiences.'
              image={tpcSubscriptionGroupNotifications}
              alt='Subscription Group Notifications'
            />
            <FeaturedContentCard
              header='Sell // Trade'
              title='Developed using Next.js and powered by Sanity as the headless content management system, this application empowers users to seamlessly submit and oversee the complete lifecycle of their products for sale.'
              image={tpcSellTrade}
              alt='Sell Trade'
            />
            <FeaturedContentCard
              header='What is Certified Pre-Owned?'
              title='This interactive modal was designed and built with intention to inform users about the benefits of buying pre-owned bikes, wheels, and frames. It leverages an embedded Vimeo services, fully customized to bring the best user experience for customers.'
              image={tpcCpo}
              alt='Certified Pre-Owned'
            />
          </div>
        )}
          <div className='container'>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '50px',
            }}>
              <SectionTitleTwo tCenter='h6' mtitle="Side Projects" />
              <div className='projectContainer'>
                {filteredProjects.map((project) =>
                  project ? (
                    <ImgMediaCard
                      key={project.id}
                      title={project.projectName}
                      img={project.img}
                      hoverImg={project.hoverImg}
                      description={project.description}
                      githubUrl={project.githubUrl}
                    />
                  ) : null
                )}
              </div>
            </div>
        </div>
      </section>
    );
  }
}

export default portfolio;
