import React from "react";
import GoogleMapReact from "google-map-react";
require('dotenv').config()

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const mapStyles = {
  width: '100%',
  height: '500px',
};

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 39.7392,
      lng: -104.9903,
    },
    zoom: 10,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={mapStyles}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process?.env?.NODE_ENV === 'development' ? 'AIzaSyBCB4y0SztfLo236n4JECwTvIQd-VecF2Y' : process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
}