import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 300,
    margin: 20,
  },
});

export default function ImgMediaCard(props) {
  const { title, img, hoverImg, description, githubUrl } = props;
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverImage = async () => {
    await setIsHovered(isHovered => !isHovered)
  }

  return (
      <Card className={classes.root} margin={20}>
        <CardActionArea>
          <a href={githubUrl} target="blank">
            <CardMedia
              component="img"
              alt={title}
              height="200"
              src={!isHovered ? require(`../../image/portfolio/${img}`) : require(`../../image/portfolio/${hoverImg}`)}
              title={title}
              onMouseOver={handleHoverImage}
              onMouseOut={handleHoverImage}
            />
          </a>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className="projectDescription" variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className="cardLinks">
          {/* <Button size="small" color="primary">
            Share
          </Button> */}
          {/* <Button size="small" color="primary">
            Learn More
          </Button> */}
          <a className="moreInfo" href={githubUrl} target="blank">Learn More</a>
        </CardActions>
      </Card>
  );
}
